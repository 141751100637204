body {
  height: 100%;
  color: #777;
  line-height: 2.0;
}

* {
   font-family : "Cambria";
}

ol, ul {
  padding-left: 30px;
}


.status {
  margin-bottom: 10px;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

/* Create a Parallax Effect */
.bgimg-1 {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* First image (Logo. Full height) */
.bgimg-1 {
  background-image: url('./images/Engagement.jpg');
  min-height: 100%;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1600px) {
  .bgimg-1 {
    background-attachment: scroll;
    min-height: 400px;
  }
}

.question {
  text-align: justified;
  padding-left: 14vw!important;
  padding-right: 14vw!important;
  font-size: 19px;
  font-weight: bold;
}

.answer {
  text-align: justified;
  padding-left: 14vw!important;
  padding-right: 14vw!important;
  padding-top: 1em!important;
  font-size: 19px;
}

.justified-large-indent {
  text-align: justified;
  padding-left: 14vw!important;
  padding-right: 14vw!important;
  
}

.justified-small-indent {
  text-align: justified;
  padding-left: 14vw!important;
}

.w3-wide {letter-spacing: 10px;}
.w3-hover-opacity {cursor: pointer;}

.mobile-menu.hide {display: none; opacity: 0; transition: opacity 0.3s ease-out}
.mobile-menu-item.hide {opacity: 0; transition: opacity 0.3s ease-out}

@media (max-width:768px){.hide-small{display:none!important}}
@media (min-width:768px){.hide-large{display:none!important}}

@media (max-width:768px){.mobile-menu{opacity: 1; width:100%!important; transition: opacity 0.3s ease-out;}}

@media (max-width:768px){.mobile-menu-item{padding-top: 0.5em!important; padding-bottom: 0.5em!important; background-color: #f7cde0; color: black!important; font-family: Cambria!important; vertical-align: middle!important; border-bottom: .5px solid black!important; transition: height 0.3s ease-in; min-height: 3em; opacity: 0.9; width:100%!important }}
@media (max-width:768px){.mobile-menu-item:hover{ opacity: 1.0;}}

@media (max-width:768px){.white-on-small{color: #000!important; background-color: #f7cde0!important}}

@media (max-width:768px){.single-column-on-small{ grid-template-columns: 100vw!important; }}
@media (max-width:768px){.map-width-on-small{ width: 0vw!important}}
@media (max-width:768px){.map-table-on-small{ width: 100vw!important}}
@media (max-width:768px){.full-width-on-small{ width: 100vw!important; padding-left: 14vw!important; padding-right: 14vw!important}}

.black-on-small{color: ##f7cde0; background-color: #000}

.theme-color {
   color: #f7cde0;
   background-color: "#fff";
}

.medium-opacity {
 opacity: .7;
}

.navbar {
  padding: 1em 2em;
  float:left;
  width:auto;
  display:inline-block;
  vertical-align:middle;
  overflow:hidden;
  text-decoration:none;
  text-align:center;
  cursor:pointer;
  white-space:nowrap;
  color: #f7cde0;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
   font-family : "Cambria";
}  

.navbar:hover {
  color:#000!important;
  background-color: #f7cde0;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.selectable-guest {
    cursor:pointer;
    color:#000;
    background-color: #f7cde0;
    border: .2rem solid #ececec;
    border-radius: 8px;
}

.selectable-guest.highlighted {
  color:#f7cde0!important;
  background-color: #000;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.selectable-guest:hover {
  color:#f7cde0!important;
  background-color: #000;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.themed-background {
   color:black;
   background-color: #f7cde0;
}

.backgroundimage {
  opacity: 0.70;
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 48em;
/*  -webkit-transition: height 0.6s ease-in-out;
  -moz-transition: height 0.6s ease-in-out;
  transition: height 0.6s ease-in-out;
*/
  transition: background-image 0.6s ease-in-out;
  background-image: url("./images/Engagement.jpg");
}

@media (max-width: 768px) {
  .backgroundimage {
    background-attachment: scroll;
    height: 60px;
  }
}

.backgroundimage.proposal {
   min-height: 40em;
   top: 90em;
}

.backgroundimage.faq {
  background-image: url("./images/Engagement2.jpg");
}

.backgroundimage.location {
  background-image: url("./images/pen-ryn-large.jpg");
}

.backgroundimage.rsvp {
  background-image: url("./images/pen-ryn-large.jpg");
}

.engagementphoto {
    opacity: 0.85;
    height: 35em;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
 }

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}

.map-marker {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}

.map-marker.hover {
  color:#f7cde0;
  font-size: 5em;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

 .Login {
    padding: 60px 0;
 }

 .Login form {
    margin: 0 auto;
    max-width: 320px;
 }

h4 {
  font-family : "Cambria";
}

h3 {
  font-size: 2.5em;
  font-family : "Cambria";
}

h2 {
  font-family : "Cambria";
}

h1 {
  font-family : "Cambria";
}

form {
    position: relative;
    color: #212529;
    padding: 1rem;
    margin-right: 0;
    margin-left: 0;
    border: .2rem solid #ececec;
    border-radius: 8px;
}

.form-container {
    border-width: .2rem .2rem 0;
    border-radius: 8px 8px 0 0;
    padding-left: 2em;
    padding-bottom: 2em;
    padding-right: 2em;
}

form-group {
    position: relative;
    color: #212529;
    padding: 1rem;
    margin-right: 0;
    margin-left: 0;
    border: .2rem solid #ececec;
    border-radius: 8px;
 }

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.main-content {
  animation: fade-in .5s;
}

.fade-in {
   animation: fade-in-partial .5s alternate-reverse;
}
.fade-out {
   animation: fade-out-partial .5s alternate-reverse;
}

@keyframes fade-in-partial {
    from {opacity: 0;}
    to {opacity: 70;}
}
@keyframes fade-out-partial {
    from {opacity: 70;}
    to {opacity: 0;}
}


@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 100;}
}


